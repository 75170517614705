import { useContext, useEffect, useRef, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { AllCoinsContext } from "../../context";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import Loader from "../misc/loader";
import { textSizes } from "../sizes";
import { Paragraph, SmallText } from "../texts.styled";
import { StyledDropdown } from "./dropdown.styled";
import DropdownMenu from "./dropdownMenu";
import { ClickAwayListener } from "@mui/base";
import { graphFormatter } from "../../util/numberFormatter";
import { theme } from "../theme.styled";

export default function Dropdown({
  isCompareWith,
  setCoin,
  defaultCoin,
  otherCoin,
  isSwitching,
  setSwitching,
  defaultExternalCoin,
  noPromotion,
  items,
  isHolderCompare,
  chainCheckCoin,
  external,
}) {
  const scrollRef = useRef(null);

  const [ison, setOn] = useState(false);

  const [selectedCoin, setSelectedCoin] = useState(
    defaultCoin ? defaultCoin : null
  );

  const allCoins = useContext(AllCoinsContext);

  function handleCoinSet(item) {
    console.log("setting", item);
    setSelectedCoin(item);
    setCoin(item);
    setOn(false);
  }

  useEffect(() => {
    if (!chainCheckCoin || !selectedCoin) return;

    console.log(selectedCoin === chainCheckCoin);

    console.log(selectedCoin, chainCheckCoin);

    if (selectedCoin.group !== chainCheckCoin.group) {
      handleCoinSet(items[0].id === chainCheckCoin.id ? items[1] : items[0]);
    }
  }, [chainCheckCoin]);

  useEffect(() => {
    if (selectedCoin === null && defaultExternalCoin !== undefined) {
      if (items) {
        setSelectedCoin(
          items?.find((item) => item.id === defaultExternalCoin.id)
        );
      } else {
        setSelectedCoin(
          allCoins?.find((item) => item.id === defaultExternalCoin.id)
        );
      }
    }
  }, []);

  // useEffect(() => {
  //   if (ison === true) {
  //     scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [ison]);

  useEffect(() => {
    if (isSwitching === true) {
      setSelectedCoin(otherCoin);
      setCoin(otherCoin);
      setSwitching(false);
    }
  }, [isSwitching]);

  return (
    <ClickAwayListener onClickAway={() => setOn(false)}>
      <Column width="100%" style={{ position: "relative" }}>
        <div
          ref={scrollRef}
          style={{ position: "absolute", top: "-10rem" }}
        ></div>
        <StyledDropdown
          ison={ison ? 1 : 0}
          onClick={() => setOn((prevState) => !prevState)}
          external={external}
        >
          {selectedCoin !== null ? (
            <FullRow justify="space-between">
              <Row gap="0.75rem">
                <img
                  alt="logo"
                  src={selectedCoin.image}
                  style={
                    isCompareWith || isHolderCompare ? { width: "1.5rem" } : {}
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "logos/empty-coin.svg";
                  }}
                />
                {selectedCoin.id.includes("custom") ? (
                  <Column align="start">
                    <Paragraph
                      size={textSizes.fontSizes.HEADER}
                      weight={textSizes.fontWeights.BOLD}
                    >
                      {graphFormatter(selectedCoin.marketCap)}
                    </Paragraph>
                    <SmallText>Market Cap</SmallText>
                  </Column>
                ) : (
                  <Column align="start">
                    <Paragraph
                      size={
                        isCompareWith || isHolderCompare
                          ? "1.2rem"
                          : textSizes.fontSizes.HEADER
                      }
                      weight={textSizes.fontWeights.BOLD}
                    >
                      {selectedCoin.symbol.toUpperCase()}
                      {isCompareWith && (
                        <SmallText
                          style={{
                            marginLeft: "1rem",
                            color: theme.colors.LIGHT_TEXT,
                          }}
                        >
                          {selectedCoin.coinName}
                        </SmallText>
                      )}
                    </Paragraph>
                    {!isCompareWith && !external && (
                      <SmallText color={theme.colors.LIGHT_TEXT}>
                        {selectedCoin.coinName}
                      </SmallText>
                    )}
                  </Column>
                )}
              </Row>

              <Icon
                reverse={ison ? 1 : 0}
                style={{ justifySelf: "end" }}
                size="2rem"
              >
                <MdExpandMore />
              </Icon>
            </FullRow>
          ) : (
            <Loader isSmall={true} />
          )}
        </StyledDropdown>
        <DropdownMenu
          noPromotion={noPromotion}
          setCoin={handleCoinSet}
          dropdownItems={items || allCoins}
          status={"success"}
          ison={ison}
          marginTop={external && "3rem"}
        />
      </Column>
    </ClickAwayListener>
  );
}
