import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import { BiChevronUp } from "react-icons/bi";
import { HiSwitchVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import { PrimaryButton, TransparentButton } from "../buttons/buttons.styled";
import AmountInput from "../compareSection/amountInput";
import Dropdown from "../compareSection/dropdown";
import { Icon } from "../icon.styled";
import { textSizes } from "../sizes";
import { LightHeader } from "../texts.styled";
import { StyledCompareAgainMenuMobile } from "./compareAgainMenuMobile.styled";
import { sendCompareButtonClick } from "../stream";

export default function CompareAgainMenuMobile({
  setMenuOn,
  coin1data,
  coin2data,
}) {
  const notify = () => toast("Please choose two different coins.");

  const [isSwitching, setSwitching] = useState(false);

  const handleSwitch = () => {
    setSwitching(true);
  };

  const [coin1, setCoin1] = useState(coin1data);
  const [coin2, setCoin2] = useState(coin2data);

  const [amount, setAmount] = useState("");
  const [amountType, setAmountType] = useState("$");

  const handleCompareButtonClick = () => {
    if (coin1.id === coin2.id) {
      notify();
      return;
    }
    sendCompareButtonClick(coin1.id, coin2.id);
    window.location.replace(
      `/compare/${coin1.id}/${coin2.id}/${
        amount !== "" && amount !== 0
          ? `${amountType === "$" ? "?$=" : "?amount="}${amount}`
          : ""
      }`
    );
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setMenuOn(false);
      }}
    >
      <StyledCompareAgainMenuMobile>
        <LightHeader>Compare Again</LightHeader>
        <Dropdown
          otherCoin={coin2}
          setSwitching={setSwitching}
          isSwitching={isSwitching}
          setCoin={setCoin1}
          defaultExternalCoin={coin1data}
        />
        <AmountInput
          upperAmount={amount}
          mode={amountType}
          setMode={setAmountType}
          setUpperAmount={setAmount}
          coin={coin1data}
          value={amount}
        />
        <TransparentButton
          aria-label="switch-button"
          onClick={handleSwitch}
          rotateonhover={"true"}
        >
          <Icon size={textSizes.fontSizes.LARGE}>
            <HiSwitchVertical />
          </Icon>
        </TransparentButton>
        <Dropdown
          otherCoin={coin1}
          setSwitching={setSwitching}
          isSwitching={isSwitching}
          setCoin={setCoin2}
          defaultExternalCoin={coin2data}
        />
        <PrimaryButton onClick={handleCompareButtonClick} isfull={true}>
          Compare Again
        </PrimaryButton>
        <TransparentButton onClick={() => setMenuOn(false)}>
          <Icon size={"2rem"}>
            <BiChevronUp />
          </Icon>
        </TransparentButton>
      </StyledCompareAgainMenuMobile>
    </ClickAwayListener>
  );
}
