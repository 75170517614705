import { useContext, useEffect, useRef, useState } from "react";
import { defaultCoins } from "../compareSection/defaultCoins";
import Dropdown from "../compareSection/dropdown";
import DropdownMenu from "../compareSection/dropdownMenu";
import { Column, Row } from "../containers/flexbox.styled";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import {
  HalvingBoxDropdownWrapper,
  HalvingResultImageRow,
  StyledHalvingBox,
  StyledHalvingBoxBackButton,
  StyledHalvingBoxWrapper,
} from "./halvingBox.styled";
import {
  OutlineButton,
  PrimaryButton,
  TransparentButton,
} from "../buttons/buttons.styled";
import { borderWidths, textSizes } from "../sizes";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import React from "react";
import Loader from "../misc/loader";
import HalvingResultThree, { dateDifferenceInDays } from "./halvingResultThree";
import HalvingGraph from "./halvingGraph";
import { useQuery } from "@tanstack/react-query";
import {
  getCoin,
  getHalvingCoins,
  getHalvingHistoricData,
  getHistoricalDataOfCoin,
} from "../stream";
import {
  AllCoinsContext,
  HalvingContext,
  HalvingDateContext,
} from "../../context";
import { IoChevronBackOutline } from "react-icons/io5";
import { Icon } from "../icon.styled";
import { formatNumber } from "../../util/numberFormatter";

export default function HalvingBox({ externalCoin }) {
  const allCoins = useContext(AllCoinsContext);

  const [coin1, setCoin1] = useState(
    externalCoin
      ? allCoins?.find((item) => item.id === externalCoin)
      : defaultCoins.ethereum
  );
  const [coin2, setCoin2] = useState(defaultCoins.bitcoin);

  const [pressed, setPressed] = useState(false);

  const scrollRef = useRef(null);

  function handleCompare() {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    setPressed(true);
  }

  function handleBackButton() {
    setCoin1(defaultCoins.ethereum);
    setCoin2(defaultCoins.bitcoin);
    setPressed(false);
  }

  const { data: halvingCoins, status: halvingCoinsStatus } = useQuery(
    ["halving-coins"],
    () => getHalvingCoins(),
    {
      onSuccess: (data) => {},
      refetchOnWindowFocus: false,
    }
  );

  return (
    <StyledHalvingBoxWrapper ref={scrollRef} pressed={pressed}>
      {pressed && <HalvingBoxBackButton handleBackButton={handleBackButton} />}
      {!pressed ? (
        <HalvingBoxLanding
          halvingCoins={halvingCoins}
          halvingCoinsStatus={halvingCoinsStatus}
          handleCompare={handleCompare}
          setCoin1={setCoin1}
          setCoin2={setCoin2}
          externalCoin={externalCoin}
        />
      ) : (
        <HalvingBoxResult
          halvingCoins={halvingCoins}
          coin1={coin1}
          coin2={coin2}
          key={new Date()}
        />
      )}
    </StyledHalvingBoxWrapper>
  );
}

function HalvingBoxBackButton({ handleBackButton }) {
  return (
    <StyledHalvingBoxBackButton
      onClick={() => {
        handleBackButton();
      }}
    >
      <TransparentButton>
        <Icon size="2rem">
          <IoChevronBackOutline />
        </Icon>
      </TransparentButton>
      <SmallText>Back</SmallText>
    </StyledHalvingBoxBackButton>
  );
}

function HalvingBoxResult({ coin1, coin2, halvingCoins }) {
  const { data: coinData, status: coinStatus } = useQuery(
    ["halving-coin", coin1],
    () => getCoin(coin1.id),
    {
      onSuccess: (data) => {},
      refetchOnWindowFocus: false,
    }
  );

  const [isLoading, setLoading] = useState(true);
  const [totalX, setTotalX] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchCoin2();
  }, []);

  const {
    data: bitcoinData,
    status: bitcoinStatus,
    refetch: fetchCoin2,
  } = useQuery(["halving-bitcoin"], () => getHalvingHistoricData(coin2.id), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setLoading(false);
    },
  });

  const oHalvingDate = useContext(HalvingDateContext);

  let halvingDifference = dateDifferenceInDays(
    new Date(oHalvingDate),
    new Date()
  );

  return (
    <React.Fragment>
      <Header>Halving Comparison</Header>
      {bitcoinData &&
      bitcoinStatus === "success" &&
      coinStatus === "success" &&
      !isLoading ? (
        <HalvingContext.Provider
          value={{ bitcoinData, coinData, halvingCoins, coin2 }}
        >
          <React.Fragment>
            <HalvingResultImageRow>
              <img src={coin1.image} />
              <Paragraph>vs</Paragraph>
              <img src={coin2.image} />
            </HalvingResultImageRow>
            <SmallText>
              ${coin1.symbol.toUpperCase()} price now: $
              {formatNumber(coinData.price)}
            </SmallText>
            <Paragraph
              weight={textSizes.fontWeights.LIGHT}
              textAlign={"center"}
              width={"90%"}
              // size={textSizes.fontSizes.HEADER}
              maxWidth={"37rem"}
            >
              <span style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>
                {coin2.coinName}
              </span>{" "}
              value increased{" "}
              <span style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>
                {totalX}x
              </span>{" "}
              from{" "}
              <span style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>
                {halvingDifference}
              </span>{" "}
              days after the halving until the end of the 2020 bull cycle. If{" "}
              <span style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>
                {coin1.coinName}
              </span>{" "}
              demonstrates similar performance, its price would be:
            </Paragraph>
            <HalvingResultThree setTotalX={setTotalX} />
            <Column gap="0.2rem" width="100%">
              <SmallText
                style={{ marginTop: "1rem" }}
                align={"center"}
                size={"1rem"}
              >
                The graphic below shows how {coin2.coinName} performed
                throughout the last halving:
              </SmallText>
              <HalvingGraph key={new Date().getTime()} coin={coin2} />
            </Column>
          </React.Fragment>
        </HalvingContext.Provider>
      ) : (
        <Loader height={"30rem"} />
      )}
    </React.Fragment>
  );
}

function HalvingBoxLanding({
  halvingCoins,
  halvingCoinsStatus,
  handleCompare,
  setCoin1,
  setCoin2,
  externalCoin,
}) {
  return halvingCoinsStatus === "success" ? (
    <React.Fragment>
      <HideOnMobileWrapper>
        <Header>Compare your coin</Header>
      </HideOnMobileWrapper>
      <ShowOnMobileWrapper>
        <LightHeader>Compare your coin</LightHeader>
      </ShowOnMobileWrapper>
      <StyledHalvingBox>
        <HalvingBoxDropdownWrapper gap="1rem" width="18rem" align="start">
          <SmallText>Your coin</SmallText>
          <Dropdown
            noPromotion={true}
            setCoin={setCoin1}
            defaultCoin={!externalCoin && defaultCoins.ethereum}
            defaultExternalCoin={{ id: externalCoin }}
          />
        </HalvingBoxDropdownWrapper>
        <HideOnMobileWrapper style={{ alignSelf: "center" }}>
          <Header>vs</Header>
        </HideOnMobileWrapper>
        <HalvingBoxDropdownWrapper gap="1rem" width="18rem" align="start">
          <SmallText>Compare to</SmallText>
          <Column width="100%" gap="0.5rem">
            <Dropdown
              noPromotion={true}
              setCoin={setCoin2}
              items={halvingCoins}
              defaultCoin={defaultCoins.bitcoin}
            />
            <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
              More coins coming soon
            </SmallText>
          </Column>
        </HalvingBoxDropdownWrapper>
        <HalvingBoxDropdownWrapper isFake="true">
          <HideOnMobileWrapper>
            <SmallText style={{ visibility: "hidden" }}>.</SmallText>
          </HideOnMobileWrapper>
          <OutlineButton
            borderWidth={borderWidths.SMALL}
            borderColor={({ theme }) => theme.colors.HALVING}
            hoverColor={({ theme }) => theme.colors.HALVING}
            onClick={() => handleCompare()}
          >
            GO
          </OutlineButton>
        </HalvingBoxDropdownWrapper>
      </StyledHalvingBox>
    </React.Fragment>
  ) : (
    <Loader />
  );
}
