import React, { useContext, useEffect, useState } from "react";
import { AllCoinsContext } from "../../context";
import { formatNumber } from "../../util/numberFormatter";
import { Row } from "../containers/flexbox.styled";
import { Paragraph } from "../texts.styled";
import { useQuery } from "@tanstack/react-query";
import { getAllCoins, getAllCoinsWithPrice } from "../stream";

export default function NavbarCoinInfo() {
  const allCoins = useContext(AllCoinsContext);

  const [coins, setCoins] = useState(null);

  const { data, status } = useQuery(
    ["navbarCoinInfo"],
    () => getAllCoinsWithPrice(),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        setCoins(data.slice(0, 4).filter((item) => item.id !== "tether"));
        setCoin(data[0]);
      },
    }
  );

  const [coinIndex, setCoinIndex] = useState(0);

  const [coin, setCoin] = useState(null);

  useEffect(() => {
    if (coins === null) return;

    setTimeout(() => {
      if (coinIndex === coins.length - 1) {
        setCoinIndex(0);
      } else setCoinIndex((prevState) => prevState + 1);

      setCoin(coins[coinIndex]);
    }, 2500);
  }, [coinIndex, coins]);

  return (
    <Row width="10rem" gap="0.2rem">
      {coin !== null && (
        <Paragraph>
          {coin.symbol.toUpperCase()}: ${formatNumber(coin.price, true)}
        </Paragraph>
      )}
    </Row>
  );
}
