import React, { createContext } from "react";

export const CoinsContext = createContext({});

export const DedicatedCoinContext = createContext({});

export const MostComparedContext = createContext({});

export const TrendsContext = createContext({});

export const PopupVisibleContext = createContext({});

export const AllCoinsContext = createContext({});

export const HalvingContext = createContext({});

export const HalvingDateContext = createContext({});

export const HolderCompareCoinsContext = createContext({});
