import styled from "styled-components";
import { FullRow, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";

export const StyledDropdownItem = styled(FullRow)`
  opacity: ${({ disabled }) => disabled && 0.3};
  img {
    width: 1.5rem;
  }

  ${Row} {
    gap: 0.5rem;
  }

  padding: 0.7rem ${paddings.LOW};
  border-radius: ${borderRads.SMALL};

  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  justify-content: space-between;

  background-color: ${({ isPromoted, theme }) =>
    isPromoted && "rgba(255,255,255,0.05)"};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  margin-right: //if isPromoted, make it 0.5rem
    ${({ isPromoted }) => !isPromoted && "0.5rem"};

  background-color: ${({ isFocused, theme }) =>
    isFocused && "rgba(255,255,255,0.1)"};

  //make border color gold if isPromoted
  border: ${({ isPromoted, theme }) =>
    isPromoted && `${borderWidths.SMALL} solid ${theme.colors.GOLD}`};
`;

export const StyledPromotedBadge = styled.div`
  background-color: transparent;
  padding: ${paddings.LOW};
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.BRAND};
  border-radius: ${borderRads.SMALL};
  font-size: ${textSizes.fontSizes.MOBILE_LITTLE_NUMBER};
  margin-left: auto;
`;

export const StyledDropdownPromoteButton = styled.a`
  background-color: rgba(255, 255, 255, 0.05);
  padding: ${paddings.LOW};
  border-radius: ${borderRads.SMALL};
  font-size: ${textSizes.fontSizes.SMALLER};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.2rem;
`;
