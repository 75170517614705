import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Column } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { paddings } from "../paddings.styled";
import { borderRads, textSizes } from "../sizes";
import DropdownItem, { PromoteButton } from "./dropdownItem";
import {
  DropdownMenuSearchInput,
  StyledDropdownMenu,
} from "./dropdownMenu.styled";
import { Paragraph, SmallText } from "../texts.styled";
import Switch from "../misc/switch";
import { theme } from "../theme.styled";
import { PrimaryButton } from "../buttons/buttons.styled";
import { formatNumber, graphFormatter } from "../../util/numberFormatter";
import MultiSwitch from "../misc/multiSwitch";
import { useNavigate } from "react-router-dom";

export default function DropdownMenu({
  status,
  ison,
  dropdownItems,
  setCoin,
  width,
  height,
  marginTop,
  left,
  isBox,
  backgroundColor,
  noPromotion,
  dontAutoFocus,
  hidePromote,
  isDreamfolio,
  customStyle,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  let allItems = dropdownItems;

  console.log("dropdownItems", dropdownItems);

  const [searchResults, setSearchResults] = useState(allItems);

  const [athMode, setAthMode] = useState(0);

  const [customMarketCap, setCustomMarketCap] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (customMarketCap < 0) {
      setCustomMarketCap(customMarketCap * -1);
    }

    //if there are any signs, remove them
    if (customMarketCap.includes("-")) {
      setCustomMarketCap(customMarketCap.replace("-", ""));
    }
    if (customMarketCap.includes("+")) {
      setCustomMarketCap(customMarketCap.replace("+", ""));
    }

    //remove all characyerts except the numbers from 0-9
    setCustomMarketCap(customMarketCap.replace(/[^0-9]/g, ""));
  }, [customMarketCap]);

  function updateSearchQuery(event) {
    let val = event.target.value;
    setSearchQuery(val);
    const regex = new RegExp(val, "i");

    const filteredAndSortedItems = [];

    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];

      if (
        regex.test(item.symbol) ||
        regex.test(item.coinName) ||
        (item.contractAddress && regex.test(item.contractAddress))
      ) {
        filteredAndSortedItems.push({ ...item, index: firstIndex(item) });
      }
    }

    filteredAndSortedItems.sort((a, b) => a.index - b.index);

    setSearchResults(filteredAndSortedItems);
  }

  const handleSelection = (item) => {
    // add athMode field to item
    if (isDreamfolio) {
      if (athMode === 1) {
        item.athMode = true;
      } else {
        item.athMode = false;
      }
    }

    setCoin(item);
  };

  let inputRef = useRef(null);

  useEffect(() => {
    if (ison === true) inputRef && inputRef.current.focus();
  }, [ison]);

  const firstIndex = (item) => {
    const [name, symbol, ca] = [
      item.coinName.toLowerCase().indexOf(searchQuery),
      item.symbol.toLowerCase().indexOf(searchQuery),
      item.contractAddress
        ? item.contractAddress.indexOf(searchQuery)
        : Infinity,
    ];

    let index = Infinity;
    for (let curr of [name, symbol, ca]) {
      if (curr === 0) {
        // Exit early if the first occurrence is found
        return 0;
      }

      if (curr < index && curr !== -1) {
        index = curr;
      }
    }

    return index;
  };

  // const promotedCoin = {
  //   id: "ethereum-bull",
  //   coinName: "ETHEREUM IS GOOD",
  //   symbol: "EBULL",
  //   image:
  //     "https://qwz547u5ood5gucs.public.blob.vercel-storage.com/images/coin-logos/ethbull.webp",
  // };
  const promotedCoin = null;

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, [searchQuery]);

  return (
    <StyledDropdownMenu
      backgroundColor={backgroundColor || "black"}
      isBox={isBox}
      left={left}
      height={height}
      width={width}
      ison={ison ? 1 : 0}
      marginTop={marginTop}
      style={customStyle}
    >
      <Column style={{ position: "sticky", top: "0", zIndex: 4 }}>
        <DropdownMenuSearchInput>
          <Icon
            padding={`0 0 0 ${paddings.MID}`}
            size={textSizes.fontSizes.BIG}
          >
            <BiSearch />
          </Icon>

          <input
            ref={inputRef}
            autoFocus={dontAutoFocus ? false : true}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e)}
            placeholder="Search"
          />
        </DropdownMenuSearchInput>

        {isDreamfolio && (
          <div
            style={{
              backgroundColor: theme.colors.OLDLEVEL,
              width: `calc(100% - 1rem)`,
              marginRight: "auto",
            }}
          >
            <Switch
              style={{}}
              fullWidth={true}
              options={[
                { name: "Current", onClick: () => setAthMode(0) },
                { name: "ATH", onClick: () => setAthMode(1) },
                { name: "Custom", onClick: () => setAthMode(2) },
              ]}
              backgroundColor={theme.colors.OLDLEVEL}
              selectedColor={theme.colors.OLDLEVEL2}
            />
          </div>
        )}
      </Column>

      <div
        ref={scrollRef}
        style={{
          overflowY: "scroll",
          paddingTop: "1.4rem",
          height: `calc(100% - 3rem)`,
        }}
      >
        <Column style={isDreamfolio ? { marginTop: "1rem" } : {}}>
          {!noPromotion &&
            (promotedCoin ? (
              <DropdownItem
                isPromoted={true}
                key={-1}
                coin={promotedCoin}
                onClick={() => {
                  navigate("/coin/ethereum-bull");
                }}
              />
            ) : (
              !hidePromote && (
                <PromoteButton>Promote your project here</PromoteButton>
              )
            ))}

          {status === "success" &&
            (isDreamfolio ? athMode !== 2 : true) &&
            (searchQuery === ""
              ? allItems?.slice(0, 25).map((item, index) => {
                  let isDisabled =
                    isDreamfolio && athMode === 1 && !item.athDate;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                      }}
                      key={index}
                    >
                      <DropdownItem
                        isDreamfolio={isDreamfolio}
                        key={index}
                        coin={item}
                        disabled={isDisabled}
                        onClick={
                          isDisabled ? () => {} : () => handleSelection(item)
                        }
                      />
                      {isDisabled && (
                        <SmallText
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          ATH not available
                        </SmallText>
                      )}
                    </div>
                  );
                })
              : searchResults?.slice(0, 100).map((item, index) => {
                  let isDisabled =
                    isDreamfolio && athMode === 1 && !item.athDate;

                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                      }}
                      key={index}
                    >
                      <DropdownItem
                        isDreamfolio={isDreamfolio}
                        key={index}
                        coin={item}
                        disabled={isDisabled}
                        onClick={
                          isDisabled ? () => {} : () => handleSelection(item)
                        }
                      />
                      {isDisabled && (
                        <SmallText
                          weight={textSizes.fontWeights.BOLD}
                          size={textSizes.fontSizes.SMALLER}
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          ATH not available
                        </SmallText>
                      )}
                    </div>
                  );
                }))}

          {isDreamfolio && athMode === 2 && (
            <Column style={{}} gap="0.5rem" width={"100%"}>
              <MultiSwitch
                options={[
                  {
                    name: "50M",
                    onClick: () => setCustomMarketCap("50000000"),
                  },
                  {
                    name: "100M",
                    onClick: () => setCustomMarketCap("100000000"),
                  },
                  {
                    name: "250M",
                    onClick: () => setCustomMarketCap("250000000"),
                  },
                  {
                    name: "500M",
                    onClick: () => setCustomMarketCap("500000000"),
                  },
                  {
                    name: "1B",
                    onClick: () => setCustomMarketCap("1000000000"),
                  },
                  {
                    name: "2B",
                    onClick: () => setCustomMarketCap("2000000000"),
                  },
                ]}
                selectedColor={theme.colors.LEVEL2}
                unSelectedColor={theme.colors.LEVEL}
                adaptiveWidth={true}
                isDreamfolio={true}
              />

              <input
                placeHolder="Custom Market Cap"
                value={Number(customMarketCap).toLocaleString()}
                onChange={(e) => setCustomMarketCap(e.target.value)}
                style={{
                  width: "100%",
                  height: "3rem",
                  backgroundColor: theme.colors.SECONDARY,
                  border: "none",
                  borderRadius: "0.5rem",
                  marginTop: "0.5rem",
                  color: "white",
                  paddingLeft: "0.5rem",
                }}
              />
              <button
                style={{
                  height: "3rem",
                  backgroundColor: theme.colors.LEVEL2,
                  width: "100%",
                  border: "none",
                  borderRadius: borderRads.SMALL,
                }}
                onClick={() => {
                  setCoin({
                    id: "custom" + new Date().getTime(),
                    coinName: graphFormatter(customMarketCap),
                    symbol: graphFormatter(customMarketCap),
                    marketCap: customMarketCap,
                    image: "logos/empty-coin.svg",
                  });
                }}
              >
                Compare with Market Cap
              </button>
            </Column>
          )}
        </Column>
      </div>
    </StyledDropdownMenu>
  );
}
