import styled from "styled-components";
import { theme } from "../theme.styled";
import { textSizes } from "../sizes";
import { getCoinRoi } from "../stream";
import Loader from "../misc/loader";
import { FullRow } from "../containers/flexbox.styled";
import { SmallText } from "../texts.styled";
import { useQuery } from "@tanstack/react-query";
import { graphFormatter } from "../../util/numberFormatter";
import { useEffect } from "react";

export default function CoinRoi({ id, coin }) {
  const {
    data,
    status,
    refetch: fetchData,
  } = useQuery(["coin-roi", id], () => getCoinRoi(id), {
    onSuccess: (data) => {},
    enabled: false,
    retry: 1,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (coin.source === "ds") return;

    fetchData();
  }, []);

  return (
    <StyledCoinRoiTable>
      {status === "success" ? (
        <>
          <tr>
            <td style={{ fontWeight: textSizes.fontWeights.SEMI_BOLD }}>ROI</td>
            <td
              style={{
                opacity: 0.75,
              }}
            >
              1h
            </td>
            <td
              style={{
                opacity: 0.75,
              }}
            >
              1d
            </td>
            <td
              style={{
                opacity: 0.75,
              }}
            >
              1m
            </td>
            <td
              style={{
                opacity: 0.75,
              }}
            >
              3m
            </td>
            <td
              style={{
                opacity: 0.75,
              }}
            >
              1y
            </td>
          </tr>
          {Object.keys(data).map((key) => {
            let name;

            switch (key) {
              case "gold":
                name = coin.symbol.toUpperCase() + " / GOLD";
                break;
              case "bitcoin":
                name =
                  coin.symbol.toUpperCase() +
                  ` / BTC${id === "bitcoin" ? "(ath)" : ""}`;
                break;
              case "ethereum":
                name = coin.symbol.toUpperCase() + " / ETH";
                break;
              case "usd":
                name = coin.symbol.toUpperCase() + " / USD";
                break;
              default:
                break;
            }

            return (
              <tr key={key}>
                <td
                  style={{
                    opacity: 0.75,
                  }}
                >
                  <SmallText size="0.75rem" style={{ whiteSpace: "nowrap" }}>
                    {name}
                  </SmallText>
                </td>
                <td
                  style={
                    data[key][0] >= 0
                      ? data[key][0] === null
                        ? {
                            color: theme.colors.LIGHT_TEXT,
                            opacity: 0.75,
                          }
                        : {
                            color: theme.colors.GREEN,
                            opacity: 0.75,
                          }
                      : {
                          color: theme.colors.RED,
                          opacity: 0.9,
                        }
                  }
                >
                  {data[key][0]
                    ? graphFormatter(data[key][0], false, true) + "%"
                    : "-"}
                </td>
                <td
                  style={
                    data[key][1] >= 0
                      ? data[key][1] === null
                        ? {
                            color: theme.colors.LIGHT_TEXT,
                            opacity: 0.75,
                          }
                        : {
                            color: theme.colors.GREEN,
                            opacity: 0.75,
                          }
                      : {
                          color: theme.colors.RED,
                          opacity: 0.9,
                        }
                  }
                >
                  {data[key][1]
                    ? graphFormatter(data[key][1], false, true) + "%"
                    : "-"}
                </td>
                <td
                  style={
                    data[key][2] >= 0
                      ? data[key][2] === null
                        ? {
                            color: theme.colors.LIGHT_TEXT,
                            opacity: 0.75,
                          }
                        : {
                            color: theme.colors.GREEN,
                            opacity: 0.75,
                          }
                      : {
                          color: theme.colors.RED,
                          opacity: 0.9,
                        }
                  }
                >
                  {data[key][2]
                    ? graphFormatter(data[key][2], false, true) + "%"
                    : "-"}
                </td>
                <td
                  style={
                    data[key][3] >= 0
                      ? data[key][3] === null
                        ? {
                            color: theme.colors.LIGHT_TEXT,
                            opacity: 0.75,
                          }
                        : {
                            color: theme.colors.GREEN,
                            opacity: 0.75,
                          }
                      : {
                          color: theme.colors.RED,
                          opacity: 0.9,
                        }
                  }
                >
                  {data[key][3]
                    ? graphFormatter(data[key][3], false, true) + "%"
                    : "-"}
                </td>
                <td
                  style={
                    data[key][4] >= 0
                      ? data[key][4] === null
                        ? {
                            color: theme.colors.LIGHT_TEXT,
                            opacity: 0.75,
                          }
                        : {
                            color: theme.colors.GREEN,
                            opacity: 0.75,
                          }
                      : {
                          color: theme.colors.RED,
                          opacity: 0.9,
                        }
                  }
                >
                  {data[key][4]
                    ? graphFormatter(data[key][4], false, true) + "%"
                    : "-"}
                </td>
              </tr>
            );
          })}
        </>
      ) : (
        <FullRow style={{ height: "100%" }}>
          {status === "error" || coin.source === "ds" ? (
            <SmallText
              color={theme.colors.LIGHT_TEXT}
              style={{ width: "100%", textAlign: "center" }}
            >
              No data.
            </SmallText>
          ) : (
            <Loader isSmall={true} />
          )}
        </FullRow>
      )}
    </StyledCoinRoiTable>
  );
}

export const StyledCoinRoiTable = styled.table`
  width: 100%;
  height: 9rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  border: 0.12rem solid ${theme.colors.OLDLEVEL2};

  font-size: ${textSizes.fontSizes.SMALL};

  border-collapse: separate;

  th {
    font-weight: ${textSizes.fontWeights.MEDIUM};
  }

  & > * {
    & > * {
      text-align: center;
      padding: 0.5rem 0.5rem;
    }
    & > *:first-child {
      width: 3rem;
      text-align: start;
    }
  }
`;

export const StyledHolderCompareLevelsTable = styled(StyledCoinRoiTable)`
  background-color: rgba(255, 255, 255, 0.03);

  border: none;

  tr:first-child {
    background-color: rgba(255, 255, 255, 0.04) !important;

    & > *:first-child {
      border-top-left-radius: 0.4rem;
    }

    & > *:last-child {
      border-top-right-radius: 0.4rem;
    }
  }

  tr:last-child {
    & > *:first-child {
      border-bottom-left-radius: 0.4rem;
    }

    & > *:last-child {
      border-bottom-right-radius: 0.4rem;
    }
  }

  tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.02);
  }
`;
