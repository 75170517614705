import styled, { keyframes } from "styled-components";
import { BlurBox } from "../containers/boxes.styled";
import { Column, FullRow } from "../containers/flexbox.styled";
import { zIndexes } from "../globalStyle.styled";
import { paddings } from "../paddings.styled";
import { borderRads, textSizes } from "../sizes";

const fadeIn = keyframes`
  from {
    height: 0rem;
  }
  to {
    height: 20rem;
  }
`;

export const DropdownMenuSearchInput = styled(FullRow)`
  gap: ${paddings.MID} !important;
  flex-direction: row !important;
  z-index: ${zIndexes.DROPDOWN_MENU_SEARCH_INPUT};
  input {
    width: 100%;
    background-color: transparent;
    color: white;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    height: 3rem;
  }
`;

export const StyledDropdownMenu = styled(BlurBox)`
  display: ${({ ison }) => !ison && "none"};
  border-radius: ${({ isBox }) =>
    !isBox
      ? `0 0 ${borderRads.SMALL} ${borderRads.SMALL}`
      : `${borderRads.SMALL}`};
  box-sizing: border-box;
  margin-top: ${({ marginTop }) => marginTop || "4.1rem"};
  position: absolute;
  width: ${({ width }) => width || "100%"};
  max-height: ${({ height }) => height || "auto"};
  z-index: ${zIndexes.DROPDOWN_MENU};
  overflow-y: hidden;
  animation: ${fadeIn} 0.3s forwards;
  left: ${({ left }) => left && left};
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};

  input:focus {
    outline: none;
  }

  @media (max-width: 40rem) {
    margin-top: ${({ marginTop }) => marginTop || "2.7rem"};
  }

  ${Column} {
  }
`;
