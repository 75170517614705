import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DedicatedCoinContext } from "../../context";
import { RouteLink } from "../../routing/routeLink";
import { add3Dots } from "../../util/numberFormatter";
import { VerticalSeperator } from "../containers/boxes.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Paragraph, SmallText } from "../texts.styled";
import { CoinInfoSection } from "./coinInfo.styled";
import { LuSwords } from "react-icons/lu";
import { Icon } from "../icon.styled";
import { paddings } from "../paddings.styled";
import { theme } from "../theme.styled";

export default function ComparedMostlyWithMobile({ data }) {
  return (
    <Column
      gap="0.5rem"
      style={{
        padding: "0.5rem",
        backgroundColor: theme.colors.LEVEL,
        borderRadius: borderRads.SMALL,
      }}
    >
      <SmallText color={({ theme }) => theme.colors.LIGHT_TEXT}>
        Compared mostly to
      </SmallText>
      <FullRow>
        {data.length >= 3 &&
          data.slice(0, 3).map((item) => (
            <FullRow
              justify="center"
              gap="0.5rem"
              style={{
                backgroundColor: theme.colors.LEVEL,
                padding: "0.4rem",
                borderRadius: borderRads.SMALL,
              }}
            >
              <img
                alt="logo"
                style={{ width: "1.2rem" }}
                src={item.coinImage2}
              />
              <RouteLink to={`/coin/${item.coin2Id}`}>
                <Paragraph weight={textSizes.fontWeights.MEDIUM}>
                  {add3Dots(item.coin2Symbol.toUpperCase(), 6)}
                </Paragraph>
              </RouteLink>
            </FullRow>
          ))}
      </FullRow>
    </Column>
  );
}
