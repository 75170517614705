import { useContext, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { CoinsContext, TrendsContext } from "../../context";
import { Row } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { SmallBadge } from "../misc/badges.styled";
import { Header, SmallText } from "../texts.styled";
import CompareAgainMenuMobile from "./compareAgainMenuMobile";
import { StyledCompareAgainMobile } from "./compareAgainMobile.styled";
import { textSizes } from "../sizes";
import { Paragraph } from "../texts.styled";

export default function CompareAgainMobile() {
  const { coin1data, coin2data } = useContext(CoinsContext);

  const [coin1MC, setCoin1MC] = useState(null);
  const [coin2MC, setCoin2MC] = useState(null);

  const [isMenuOn, setMenuOn] = useState(false);

  const { mcData, trendingData } = useContext(TrendsContext);

  useEffect(() => {
    setCoin1MC(
      mcData.slice(0, 100).findIndex((item) => item.id === coin1data.id)
    );
    setCoin2MC(
      mcData.slice(0, 100).findIndex((item) => item.id === coin2data.id)
    );
  }, []);

  return (
    <StyledCompareAgainMobile isMenuOn={isMenuOn}>
      <Row
        justify={"center"}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setMenuOn(true);
        }}
      >
        <Row justify={"end"} style={{ width: "40%" }} gap={"0.5rem"}>
          {coin1MC !== null && !isNaN(coin1MC) && coin1MC >= 0 && (
            <SmallBadge style={{ justifySelf: "start" }}>
              #{coin1MC + 1}
            </SmallBadge>
          )}
          <Paragraph size={"1.2rem"} weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin1data.symbol.toUpperCase()}
          </Paragraph>
          <img alt="logo" src={coin1data.image} />
        </Row>
        <SmallText>vs</SmallText>
        <Row justify={"start"} style={{ width: "40%" }} gap={"0.5rem"}>
          <img alt="logo" src={coin2data.image} />
          <Paragraph size={"1.2rem"} weight={textSizes.fontWeights.SEMI_BOLD}>
            {coin2data.symbol.toUpperCase()}
          </Paragraph>
          {coin2MC !== null && !isNaN(coin2MC) && coin2MC >= 0 && (
            <SmallBadge style={{ justifySelf: "start" }}>
              #{coin2MC + 1}
            </SmallBadge>
          )}
        </Row>
      </Row>

      <button onClick={() => setMenuOn(true)}>
        <Icon size={"2rem"}>
          <BiChevronDown />
        </Icon>
      </button>

      {isMenuOn && (
        <CompareAgainMenuMobile
          coin1data={coin1data}
          coin2data={coin2data}
          setMenuOn={setMenuOn}
        />
      )}
    </StyledCompareAgainMobile>
  );
}
