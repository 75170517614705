import { useContext, useEffect, useState } from "react";
import { HiSwitchVertical } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PrimaryButton, TransparentButton } from "../buttons/buttons.styled";
import { Column } from "../containers/flexbox.styled";
import { Icon } from "../icon.styled";
import { borderWidths, GRID_GAP, textSizes } from "../sizes";
import { LightHeader, SmallText } from "../texts.styled";
import AmountInput from "./amountInput";
import { StyledCompare } from "./compare.styled";
import { defaultCoins } from "./defaultCoins";
import Dropdown from "./dropdown";
import { getHolderCompareList, sendCompareButtonClick } from "../stream";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import Switch from "../misc/switch";
import { useQuery } from "@tanstack/react-query";
import Loader from "../misc/loader";
import { Col } from "antd";
import styled from "styled-components";
import { theme } from "../theme.styled";
import { set } from "date-fns";
import { AllCoinsContext } from "../../context";

export default function Compare({ external, defaultCoin1, defaultCoin2 }) {
  const notify = () => toast("Please choose two different coins.");

  const [coin1, setCoin1] = useState(defaultCoin1 || defaultCoins.ethereum);
  const [coin2, setCoin2] = useState(defaultCoin2 || defaultCoins.bitcoin);

  const [holderCoin1, setHolderCoin1] = useState(null);
  const [holderCoin2, setHolderCoin2] = useState(null);

  const [isSwitching, setSwitching] = useState(false);

  const navigate = useNavigate();

  const handleSwitch = () => {
    setSwitching(true);
  };

  const [amount, setAmount] = useState("");
  const [amountType, setAmountType] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("mode") === "holder" ? "holder" : "marketcap";

    if (type === "holder") {
      fetchHolderList();
      setCompareType(type);
    }
  }, []);

  const [compareType, setCompareType] = useState("marketcap");

  const handleCompareButtonClick = () => {
    if (compareType === "compare" && coin1.id === coin2.id) {
      notify();
      return;
    }

    if (compareType === "holder" && holderCoin1.id === holderCoin2.id) {
      notify();
      return;
    }

    sendCompareButtonClick(coin1.id, coin2.id);
    if (compareType === "marketcap") {
      navigate(
        `/compare/${coin1.id}/${coin2.id}/${
          amount !== "" && amount !== 0
            ? `${amountType === "$" ? "?$=" : "?amount="}${amount}`
            : ""
        }`
      );
    } else if (compareType === "holder") {
      navigate(`/holder-compare/${holderCoin1.id}/${holderCoin2.id}`);
    }
  };
  const allCoins = useContext(AllCoinsContext);

  const {
    data: holderList,
    status,
    refetch: fetchHolderList,
  } = useQuery(["getHolderCoinsList"], () => getHolderCompareList(), {
    enabled: false,
    staleTime: Infinity,
    onSuccess: (data) => {
      setHolderCoin1(data.find((item) => item.id === "bobo-coin-eth"));
      setHolderCoin2(data.find((item) => item.id === "pepe"));
    },
  });

  useEffect(() => {
    return () => {
      setHolderCoin1(null);
      setHolderCoin2(null);
    };
  }, []);

  return (
    <StyledCompare withborder={"true"} external={external}>
      <Column width="100%" gap={external ? "1.1rem" : "1rem"}>
        {external ? (
          <LightHeader>compare</LightHeader>
        ) : (
          <Switch
            isCompare={true}
            center={"true"}
            selectedIndexs={searchParams.get("mode") === "holder" ? 1 : 0}
            hasNew={true}
            options={[
              {
                name: "Market Cap",
                onClick: () => {
                  setCompareType("marketcap");
                  navigate("/");
                },
              },
              {
                name: "Holder",
                isNew: true,
                onClick: () => {
                  fetchHolderList();
                  setCompareType("holder");
                  navigate("/?mode=holder");
                },
              },
            ]}
          />
        )}

        {compareType === "marketcap" ? (
          <>
            <Dropdown
              defaultCoin={defaultCoin1 || coin1}
              setCoin={setCoin1}
              otherCoin={coin2}
              isSwitching={isSwitching}
              setSwitching={setSwitching}
              external={external}
            />
            <AmountInput
              upperAmount={amount}
              mode={amountType}
              setMode={setAmountType}
              setUpperAmount={setAmount}
              coin={coin1}
              value={amount}
            />
            <HideOnMobileWrapper>
              <TransparentButton
                aria-label="swap-button"
                onClick={handleSwitch}
                rotateonhover={"true"}
              >
                <Icon
                  size={
                    external
                      ? textSizes.fontSizes.HEADER
                      : textSizes.fontSizes.LARGE
                  }
                >
                  <HiSwitchVertical />
                </Icon>
              </TransparentButton>
            </HideOnMobileWrapper>
            <ShowOnMobileWrapper>vs</ShowOnMobileWrapper>
            <Dropdown
              external={external}
              defaultCoin={defaultCoin2 || coin2}
              setCoin={setCoin2}
              items={allCoins.filter((item) => item.id !== coin1.id)}
              otherCoin={coin1}
              isSwitching={isSwitching}
              setSwitching={setSwitching}
            />
          </>
        ) : holderList && holderCoin1 && holderCoin2 ? (
          <StyledHolderCompareSection
            width="100%"
            height={"15.5rem"}
            justify="space-between"
          >
            <SmallText style={{ textAlign: "center" }} className="holder-text">
              See common holders, whales, and other important metrics.
            </SmallText>
            <Dropdown
              defaultCoin={holderList.find(
                (item) => item.id === "bobo-coin-eth"
              )}
              items={holderList}
              setCoin={setHolderCoin1}
              otherCoin={holderCoin2}
              isSwitching={isSwitching}
              setSwitching={setSwitching}
            />
            vs
            <Dropdown
              items={holderList.filter(
                (item) =>
                  item.group === holderCoin1.group && item.id !== holderCoin1.id
              )}
              defaultCoin={holderList.find((item) => item.id === "pepe")}
              setCoin={setHolderCoin2}
              otherCoin={holderCoin1}
              isSwitching={isSwitching}
              setSwitching={setSwitching}
              chainCheckCoin={holderCoin1}
            />
          </StyledHolderCompareSection>
        ) : (
          <Column className="holder-loader">
            <Loader isSmall={true} />
          </Column>
        )}

        <PrimaryButton
          //if external, make backgroundColor theme.colors.LEVEL and hoverColor theme.colors.LEVEL
          backgroundColor={external && theme.colors.LEVEL2}
          hoverColor={external && theme.colors.LEVEL2}
          onClick={handleCompareButtonClick}
          isfull={"true"}
          style={external ? { fontSize: "0.9rem" } : {}}
        >
          Compare {external && "Again"}
        </PrimaryButton>
      </Column>
    </StyledCompare>
  );
}

const StyledHolderCompareSection = styled(Column)`
  height: 15.5rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .holder-text {
    color: ${theme.colors.LIGHT_TEXT};
  }

  //for mobile,
  @media (max-width: 768px) {
    height: 12.07rem;
    gap: 0.75rem;
    .holder-text {
      color: ${theme.colors.LIGHT_TEXT};
    }
  }
`;
