import { Icon } from "../icon.styled";
import { BiSolidShareAlt } from "react-icons/bi";
import { TransparentButton } from "../buttons/buttons.styled";
import React, { useContext, useState } from "react";
import { StyledShareMenu } from "../menus/shareMenu.styled";
import { ClickAwayListener } from "@mui/base";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaTwitter, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";
import { SmallText } from "../texts.styled";
import { Row } from "../containers/flexbox.styled";
import { CoinsContext, DedicatedCoinContext } from "../../context";
import CopyButton from "../misc/copyButton";
import { textSizes } from "../sizes";
import styled from "styled-components";
import { RiTwitterXLine } from "react-icons/ri";
import { formatNumber } from "../../util/numberFormatter";

export default function CompareShareButton({ isMobile, coinName }) {
  const [isOn, setOn] = useState(false);

  const { coin1data, coin2data } = useContext(CoinsContext);

  return (
    <CompareShareButtonWrapper>
      <TransparentButton onClick={() => setOn(true)}>
        <Icon size="1.5rem" color={({ theme }) => theme.colors.LIGHT_TEXT}>
          <BiSolidShareAlt />
        </Icon>
      </TransparentButton>
      {isOn && (
        <ClickAwayListener onClickAway={() => setOn(false)}>
          <StyledShareMenu>
            <SmallText>{`Share ${coin1data.symbol.toUpperCase()} vs ${coin2data.symbol.toUpperCase()}`}</SmallText>
            <Row gap="1rem">
              <TwitterShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={`If ${coin1data.symbol.toUpperCase()} reaches ${coin2data.symbol.toUpperCase()}'s current market cap, its price would be $${formatNumber(
                  coin1data.price * (coin2data.marketcap / coin1data.marketcap)
                )}. That makes ${formatNumber(
                  (coin1data.price *
                    (coin2data.marketcap / coin1data.marketcap)) /
                    coin1data.price
                )}x!
                `}
                url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
                hashtags={[coin1data.coinName, coin2data.coinName]}
              >
                <Icon>
                  <RiTwitterXLine />
                </Icon>
              </TwitterShareButton>
              <WhatsappShareButton
                style={{ display: "flex", alignItems: "center" }}
                title={`If ${coin1data.symbol.toUpperCase()} reaches ${coin2data.symbol.toUpperCase()}'s current market cap, its price would be $${formatNumber(
                  coin1data.price * (coin2data.marketcap / coin1data.marketcap)
                )}. That makes ${formatNumber(
                  (coin1data.price *
                    (coin2data.marketcap / coin1data.marketcap)) /
                    coin1data.price
                )}x!
                `}
                url={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
              >
                <Icon>
                  <FaWhatsapp />
                </Icon>
              </WhatsappShareButton>
              <CopyButton
                text={`comparemarketcap.com/compare/${coin1data.id}/${coin2data.id}`}
              />
            </Row>
          </StyledShareMenu>
        </ClickAwayListener>
      )}
    </CompareShareButtonWrapper>
  );
}

export const CompareShareButtonWrapper = styled(Row)`
  position: absolute;
  right: 0;
  width: fit-content;
  @media (max-width: 70rem) {
    position: relative !important;
  }
`;
