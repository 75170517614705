import { useContext } from "react";
import { PopupVisibleContext } from "../../context";
import {
  StyledBigBanner,
  StyledBigBannerWrapper,
  StyledBigBannerWrapperMobile,
  StyledTopTwoBanners,
} from "./banners.styled";

export default function Banners() {
  const { isPopupVisible, setPopupVisible } = useContext(PopupVisibleContext);

  /*<BannerButtonWrapper>
    <BannerButton onClick={() => !isPopupVisible && setPopupVisible(true)}>
    See What's New
    </BannerButton>
    <a href={"mailto:contact@comparemarketcap.com"}>
        <BannerButtonLight>
            Advertise Here
        </BannerButtonLight>
    </a>
</BannerButtonWrapper>*/

  return (
    <StyledTopTwoBanners>
      <StyledBigBannerWrapper>
        {/* <a target={"_blank"} href={"https://www.wawaacto.com/"}>
          <StyledBigBanner
            alt="WAWA Banner"
            src={"banners/wawa-wide.webp"}
          />
        </a> */}
        <a target={"_blank"} href={"https://t.me/hahayesonsol"}>
          <StyledBigBanner
            alt="Rizo Banner"
            src={"banners/rizo-banner.webp"}
          />
        </a>
      </StyledBigBannerWrapper>
      <StyledBigBannerWrapperMobile>
        {/* <a
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          target={"_blank"}
          href={"https://www.wawaacto.com/"}
        >
          <StyledBigBanner
            alt="WAWA Banner"
            src={"banners/wawa-wide.webp"}
          />
        </a> */}
        <a
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          target={"_blank"}
          href={"https://t.me/hahayesonsol"}
        >
          <StyledBigBanner
            alt="Rizo Banner"
            src={"banners/rizo-banner.webp"}
          />
        </a>
      </StyledBigBannerWrapperMobile>
    </StyledTopTwoBanners>
  );
}

export function DiscountBanner() {
  return (
    <StyledTopTwoBanners>
      <StyledBigBannerWrapper>
        <a href={"mailto:contact@comparemarketcap.com"}>
          <StyledBigBanner src={"banners/sale-banner-desktop.png"} />
        </a>
      </StyledBigBannerWrapper>
      <StyledBigBannerWrapperMobile>
        <a href={"mailto:contact@comparemarketcap.com"}>
          <StyledBigBanner src={"banners/sale-banner-mobile.png"} />
        </a>
      </StyledBigBannerWrapperMobile>
    </StyledTopTwoBanners>
  );
}
