import React from "react";
import { add3Dots } from "../../util/numberFormatter";
import { Row } from "../containers/flexbox.styled";
import { SmallBadge } from "../misc/badges.styled";
import { MobileSearchRow } from "../search/searchBar.styled";
import { textSizes } from "../sizes";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import { theme } from "../theme.styled";
import {
  StyledDropdownItem,
  StyledDropdownPromoteButton,
  StyledPromotedBadge,
} from "./dropdownItem.styled";
import { FiExternalLink } from "react-icons/fi";
import { Icon } from "../icon.styled";
import { HideOnMobileWrapper } from "../hideOnMobile";

export default function DropdownItem({
  coin,
  mcNumber,
  onClick,
  isPromoted,
  disabled,
  isDreamfolio,
  isFocused,
}) {
  return (
    <StyledDropdownItem
      isFocused={isFocused}
      disabled={disabled}
      className="dropdown-item"
      isPromoted={isPromoted}
      onClick={onClick}
      isDreamfolio={isDreamfolio}
    >
      {isPromoted && (
        <SmallText
          size={textSizes.fontSizes.MOBILE_LITTLE_NUMBER}
          style={{
            position: "absolute",
            top: "-0.5rem",
            right: "0",
            left: "0",
            padding: "0.2rem",
            borderRadius: "0.25rem",
            marginLeft: "auto",
            marginRight: "auto",
            width: "fit-content",
            color: theme.colors.GOLD2,
            backgroundColor: "black",
          }}
        >
          Promoted
        </SmallText>
      )}

      <Row>
        <img alt="logo" src={coin.image} />
        <Paragraph size={"1rem"} weight={textSizes.fontWeights.BOLD}>
          {coin.symbol.toUpperCase()}
        </Paragraph>
        <SmallText size={"0.8rem"} color={theme.colors.LIGHT_TEXT}>
          {coin.coinName}
        </SmallText>
      </Row>
      {isPromoted && (
        <Row style={{ marginLeft: "auto", width: "fit-content" }} gap="0.5rem">
          {
            <HideOnMobileWrapper>
              <PromotedBadge />
            </HideOnMobileWrapper>
          }
          <Icon style={{ marginLeft: "auto" }}>
            <FiExternalLink />
          </Icon>
        </Row>
      )}
      {mcNumber !== 0 && <SmallBadge>{mcNumber}</SmallBadge>}
    </StyledDropdownItem>
  );
}

export function SearchMenuItem({ img, symbol, name, isFocused }) {
  return (
    <MobileSearchRow isFocused={isFocused}>
      <img alt="logo" src={img} />
      <Header size={"1.2rem"}>{symbol.toUpperCase()}</Header>
      <Paragraph>{add3Dots(name, 10)}</Paragraph>
    </MobileSearchRow>
  );
}

export function PromotedBadge() {
  return <StyledPromotedBadge>PROMOTED</StyledPromotedBadge>;
}

export function PromoteButton() {
  return (
    <StyledDropdownPromoteButton href="mailto:contact@comparemarketcap.com">
      Promote your project here
      <Icon size="1rem">
        <FiExternalLink />
      </Icon>
    </StyledDropdownPromoteButton>
  );
}
