import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import Compare from "../components/compareSection/compare";
import { Container } from "../components/containers/boxes.styled";
import { Column, FullRow } from "../components/containers/flexbox.styled";
import HomePageMainTable from "../components/homePageMainTable/homePageMainTable";
import CookieWarning from "../components/misc/cookieConsent";
import MostCompared from "../components/misc/mostCompared/mostCompared";
import { GRID_GAP, textSizes } from "../components/sizes";
import TopGainingTable from "../components/tables/topGainingTable";
import ThreeListSection from "../components/threeListSection/threeListSection";
import Banners from "../components/misc/banners";
import PageHeader from "../components/misc/pageHeader";
import { CardsWrapper } from "../components/promote/cards.styled";
import ListingCard, { PromotionCard } from "../components/promote/listingCard";
import { BigText, Header, Paragraph } from "../components/texts.styled";
import { RiPlayListAddFill } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";
import { PiTargetLight } from "react-icons/pi";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { LuBadge } from "react-icons/lu";
import { HideOnMobileWrapper } from "../components/hideOnMobile";
import { IoReorderTwoOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";

export default function Promote() {
  let [searchParams, setSearchParams] = useSearchParams();

  //create a ref for scrolling
  const scrollRef = React.useRef();

  //scroll to the top of the page
  useEffect(() => {
    if (
      searchParams.get("ad") === "home-page-comparison" &&
      scrollRef.current
    ) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollRef]);

  return (
    <Container>
      <Helmet>
        <title>Promote - CompareMarketCap</title>
        <meta
          name="description"
          content={"List or promote your coin on CompareMarketCap"}
        />
      </Helmet>
      {/* <PageHeader
        title={"Promote"}
        description={"List or promote your project on CompareMarketCap"}
      /> */}
      <HideOnMobileWrapper>
        <br />
      </HideOnMobileWrapper>
      <Paragraph
        weight={textSizes.fontWeights.BOLD}
        className="promoteHeader"
        width="100%"
        align="center"
        size={"1.2rem"}
      >
        Listing
      </Paragraph>
      <CardsWrapper>
        {listingPackages.map((item) => (
          <ListingCard data={item} />
        ))}
      </CardsWrapper>
      <Paragraph
        weight={textSizes.fontWeights.BOLD}
        className="promoteHeader"
        style={{ marginTop: "1rem" }}
        width="100%"
        align="center"
        size={"1.2rem"}
      >
        Promotion
      </Paragraph>
      <CardsWrapper>
        {promotionPackages.map((item) => {
          return (
            <div ref={item.name === "Homepage Compare" ? scrollRef : null}>
              <PromotionCard data={item} />
            </div>
          );
        })}
      </CardsWrapper>
    </Container>
  );
}

const listingPackages = [
  {
    name: "Basic",
    icon: <IoReorderTwoOutline />,
    service1: "Listing on the website",
    icon1: <RiPlayListAddFill />,
    service2: "",
    icon2: (
      <HideOnMobileWrapper>
        <div style={{ visibility: "hidden" }}>
          <RiPlayListAddFill />
        </div>
      </HideOnMobileWrapper>
    ),
    service3: "",
    icon3: (
      <HideOnMobileWrapper>
        <div style={{ visibility: "hidden" }}>
          <RiPlayListAddFill />
        </div>
      </HideOnMobileWrapper>
    ),
    price: 150,
    isRecommended: false,
  },
  {
    name: "Target",
    icon: <PiTargetLight />,
    service1: "Listing on the website",
    icon1: <RiPlayListAddFill />,
    service2: "Listed post on X",
    icon2: <RiTwitterXLine />,
    service3: "1 compare post on X",
    icon3: <RiTwitterXLine />,
    price: 350,
    isRecommended: false,
  },
  {
    name: "Enhanced",
    icon: <LuBadge />,
    service1: "Listing on the website",
    icon1: <RiPlayListAddFill />,
    service2: "Listed post on X",
    icon2: <RiTwitterXLine />,
    service3: "2 compare posts on X",
    icon3: <RiTwitterXLine />,
    price: 450,
    isRecommended: true,
  },
];

const promotionPackages = [
  {
    name: "Banner Ad",
    image: "/images/promote/banner-spot.png",
    service1: "1 day",
    price1: 150,
    service2: "1 week",
    price2: 750,
    service3: "1 month",
    price3: 2500,
    isRecommended: false,
  },
  {
    name: "Homepage Compare",
    image: "/images/promote/home-page-compare.png",
    service1: "1 day",
    price1: 100,
    service2: "1 week",
    price2: 500,
    service3: "1 month",
    price3: 1750,
    isRecommended: false,
  },
  {
    name: "Highlighted Coin",
    image: "/images/promote/highlighted-coin.webp",
    service1: "1 day",
    price1: 100,
    service2: "1 week",
    price2: 500,
    service3: "1 month",
    price3: 1750,
    isRecommended: false,
  },
  {
    name: "Dropdown Pin",
    image: "/images/promote/dropdown.png",
    service1: "1 day",
    price1: 150,
    service2: "1 week",
    price2: 750,
    service3: "1 month",
    price3: 2500,
    isRecommended: false,
  },
];
