import styled from "styled-components";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import { paddings } from "../paddings.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { Icon } from "../icon.styled";
import { PrimaryButton } from "../buttons/buttons.styled";
import { Header, SmallText } from "../texts.styled";

export const StyledListingCard = styled(Column)`
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  width: 17.5rem;
  gap: 1rem;
  padding: 1rem 2rem;
  border-radius: ${borderRads.LARGE};
  border: ${({ isRecommended, theme }) =>
    isRecommended && `${borderWidths.MEDIUM} solid ${theme.colors.BLUE}`};

  ${FullRow} {
    justify-content: space-between;
  }

  img {
    width: 110%;
  }
  ${PrimaryButton} {
    width: 100%;
  }

  a {
    width: 100%;
    margin-top: 0.5rem;
  }

  @media (max-width: 70rem) {
    margin-top: ${({ isRecommended }) =>
      isRecommended ? "1rem" : "0"} !important;
    gap: 0.5rem;
    padding: 0.75rem;
    width: 100%;
    ${Icon} {
      svg {
        height: 1rem;
        width: auto;
      }
    }
    .headerIcon {
      svg {
        height: 2rem;
        width: auto;
      }
    }
    ${PrimaryButton} {
      padding: ${paddings.LOW} 2rem;
      margin: 0 auto;
      width: fit-content !important;
    }
    a {
      width: fit-content;
    }
    ${SmallText} {
      color: ${({ theme }) => theme.colors.LIGHT_TEXT};
    }
    .serviceIcon {
      fill: ${({ theme }) => theme.colors.LIGHT_TEXT};
    }
    img {
      width: 100%;
    }
  }
`;

export const StyledPromoteCard = styled(StyledListingCard)`
  width: 19rem;
  @media (max-width: 70rem) {
    width: 100%;
    max-width: 27rem;
  }
  ${SmallText} {
    color: white;
    font-size: ${textSizes.fontSizes.MEDIUM};
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;

    svg {
      height: 1.5rem;
    }
  }
  & > *:first-child {
    justify-content: center;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 3rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 70rem) {
    margin-top: 0;
    gap: 1rem;
  }
`;

export const RecommendedBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.BLUE};
  position: absolute;
  border-radius: ${borderRads.SMALL} ${borderRads.SMALL} 0 0;
  top: -1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${paddings.MID};
`;
