import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DedicatedCoinContext } from "../../context";
import { RouteLink } from "../../routing/routeLink";
import { add3Dots } from "../../util/numberFormatter";
import { StyledShortBoxContentWrapper } from "../misc/shortBoxContentWrapper.styled";
import { Header, LightHeader, Paragraph, SmallText } from "../texts.styled";
import {
  CMWimage,
  StyledComparedMostlyWith,
  StyledComparedMostlyWithCoin,
} from "./comparedMostlyWith.styled";
import { borderRads, borderWidths } from "../sizes";
import { FullRow, Column } from "../containers/flexbox.styled";
import { theme } from "../theme.styled";
import { textSizes } from "../sizes";

export default function ComparedMostlyWith({ data }) {
  const navigate = useNavigate();

  const coinData = useContext(DedicatedCoinContext);

  return (
    data &&
    data.length >= 3 && (
      <StyledShortBoxContentWrapper
        style={{
          borderRadius: `${borderRads.SMALL}`,
          border: `${borderWidths.SMALL} solid rgba(255, 255, 255, 0.1)`,
          padding: "1rem",
          paddingBottom: "0.5rem",
          paddingRight: "0.5rem",
          paddingLeft: "0.5rem",
        }}
        withBackground
      >
        <LightHeader
          style={{
            fontSize: "0.9rem",
          }}
        >
          compared mostly with
        </LightHeader>
        <FullRow style={{ gap: "0.5rem" }} justify="space-between">
          {data.slice(0, 3).map((item, index) => (
            <RouteLink
              to={`/coin/${item.coin2Id}`}
              key={index}
              style={{ width: "100%", display: "flex" }}
            >
              <Column
                className="compared-mostly-with"
                key={index}
                gap="0.5rem"
                style={{
                  width: "100%",
                  padding: "0.5rem",
                  backgroundColor: theme.colors.LEVEL,
                  borderRadius: `${borderRads.SMALL}`,
                }}
              >
                <img
                  style={{
                    width: "2.5rem",
                  }}
                  src={item.coinImage2}
                  alt={item.coin2Id}
                />
                <SmallText
                  weight={textSizes.fontWeights.MEDIUM}
                  color={theme.colors.LIGHT_TEXT}
                  size={textSizes.fontSizes.SMALLER}
                >
                  {add3Dots(item.coin2Symbol.toUpperCase(), 7)}
                </SmallText>
              </Column>
            </RouteLink>
          ))}
        </FullRow>
      </StyledShortBoxContentWrapper>
    )
  );
}
